<template>
    <!-- Top Nav -->
    <div class="bg-black fixed w-full z-30 lg:hidden">
        <TopNavbar @toggle="toggleSideMenu"/>
    </div>
    <main class="relative w-screen min-h-screen flex overflow-x-hidden">
        <!-- Main Content -->
        <section 
            class="flex-1 transition-all duration-100">
            <router-view></router-view>
        </section>
        <!-- Nav Items -->
        <div class="nav-items-wrapper z-20 transition-all duration-500 fixed right-0 h-full text-white"
            :class="{'lg:w-screen/3 w-full' : menuIsOpen, 'w-0' : !menuIsOpen}">
            <div class="flex h-full">
                <NavItems @itemClicked="closeMenu"/>
            </div>
        </div>
        <!-- Side Nav -->
        <aside class="bg-black w-20 z-40 fixed right-0 h-screen hidden lg:block">
            <SideNavbar @toggle="toggleSideMenu"/>
        </aside>
    </main> 
    
</template>
<script>
import SideNavbar from '@/components/SideNavbar'
import NavItems from '@/components/NavItems'
import TopNavbar from '@/components/TopNavbar'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'App',
    components: {
        SideNavbar,
        NavItems,
        TopNavbar
    },
    data(){
        return {
           
        }
    },

    computed: {
       ...mapGetters(['menuIsOpen'])
    },

    methods: {
        toggleSideMenu(value){
            this.toggleMenuIsOpen(value)
        },
        async closeMenu(){
            await this.toggleMenuIsOpen(false)
        },
        ...mapActions(['toggleMenuIsOpen'])
    }
}
</script>

<style>
    html {
        scroll-behavior: smooth;
    }
    body{
        overflow-x: hidden!important;
    }
    .nav-items-wrapper{
        background: rgb(0, 0, 0, 0.9);
    }
</style>
  