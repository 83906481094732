<template>
    <button class="w-full flex justify-center text-gray-100 z-40 cursor-pointer menu-btn" @click="toggleOpen">
        <span class="">
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                class="h-8 w-8 transform rotate-180 menu-icon"
                :class="{'block' : !menuIsOpen, 'hidden' : menuIsOpen}"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                class="h-8 w-8 menu-icon"
                :class="{'block' : menuIsOpen, 'hidden' : !menuIsOpen}"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </span>
    </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'MenuButton',
    data(){
        return {
        }
    },

    computed: {
        ...mapGetters(['menuIsOpen'])
    },

    methods: {
        async toggleOpen(){
            let isOpen = !this.menuIsOpen
            await this.toggleMenuIsOpen(isOpen)
        },
        ...mapActions(['toggleMenuIsOpen'])
    }
}
</script>

<style>

</style>