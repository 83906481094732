<template>
    <section class="px-24 pr-40 my-auto w-full space-y-6 xl:space-y-10 nav-items">
        <router-link
            @click="$emit('itemClicked')"
            to="/"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Home
        </router-link>
        <router-link 
            @click="$emit('itemClicked')"
            to="/work"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Work
        </router-link>
        <router-link
            @click="$emit('itemClicked')"
            to="/services"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Services
        </router-link>
        <router-link
            @click="$emit('itemClicked')"
            to="/movies"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Movies
        </router-link>
        <router-link
            @click="$emit('itemClicked')"
            to="/shows"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Shows
        </router-link>
        <router-link 
            @click="$emit('itemClicked')"
            to="/artists"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Artists
        </router-link>
        <router-link 
            @click="$emit('itemClicked')"
            to="/#about-us"
            class="block lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            About Us
        </router-link>
        <router-link
            @click="$emit('itemClicked')"
            to="/#contact-us"
            class="block  lg:pl-10 text-gray-300 link link--elara text-2xl w-full hover:text-white">
            Contact
        </router-link>
    </section>
</template>

<script>
export default {
    name: 'NavItems'
}
</script>

<style scoped>

</style>