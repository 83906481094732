import { createStore } from 'vuex'

export default createStore({
    state: {
        menuIsOpen: false
    },
    getters: {
        menuIsOpen: (state) => state.menuIsOpen
    },
    mutations: {
        updateMenuIsOpen: async(state, value) => {
            state.menuIsOpen = value
        }
    },
    actions: {
        toggleMenuIsOpen: async({commit}, value) => {
            commit('updateMenuIsOpen', value)
        }
    },
    modules: {
    }
})
