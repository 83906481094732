import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "tailwindcss/tailwind.css"
import '@fortawesome/fontawesome-free/css/all.min.css'
import { createMetaManager,  plugin as vueMetaPlugin } from 'vue-meta'

const metaManager = createMetaManager()

createApp(App)
    .use(store)
    .use(router)
    .use(metaManager)
    .use(vueMetaPlugin)
    .mount('#app')
