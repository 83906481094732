<template>
    <nav class="w-full h-full z-40">
        <div class="h-full flex flex-col justify-between mx-auto pb-4">
            <div class="text-center h-auto">
                <router-link to="/">
                    <img src="@/assets/img/p.png" alt="Platinum Tags Media Logo" class="object-cover h-20 w-full">
                </router-link>
            </div>
            <div class="text-gray-200 w-full py-48">
                <MenuButton @clicked="toggleSideMenu"/>
            </div>
            <div class="text-white text-center space-y-4 relative bottom-4">
                <a 
                    class="text-2xl text-green-500 cursor-pointer block hover:text-green-600" 
                    href="https://wa.me/19293524485" 
                    target="_blank">
                    <i class="fab fa-whatsapp"></i>
                </a>
                
                <a 
                    class="text-2xl text-pink-600 cursor-pointer block hover:text-pink-700" 
                    href="https://www.instagram.com/platinumtags/"
                    target="_blank">
                    <i class="fab fa-instagram"></i>
                </a>
                <a 
                    class="text-2xl text-blue-500 cursor-pointer block hover:text-blue-600" 
                    href="https://www.facebook.com/platinumtags"
                    target="_blank">
                    <i class="fab fa-facebook"></i>
                </a>
                <a 
                    class="text-2xl text-red-600 cursor-pointer block hover:text-red-700" 
                    href="https://www.youtube.com/channel/UCbFfOYcqnCVbhz19StW-NlA"
                    target="_blank">
                    <i class="fab fa-youtube"></i>
                </a>
                <a 
                    class="text-2xl cursor-pointer block twine" 
                    href="http://www.twine.fm/Baskerben" 
                    target="_blank">
                    <img src="@/assets/img/twine.png" alt="twine" class="h-7 mx-auto">
                </a>
            </div>
        </div>
    </nav>
    
</template>

<script>
import '@/assets/css/links.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import MenuButton from '@/components/MenuButton'
export default {
    name: 'SideNavbar',
    components: {
        MenuButton
    },

    data(){
        return {
            opened: false     
        }
    },

    methods: {
        toggleSideMenu(){
            this.opened = !this.opened
            this.$emit('toggle', this.opened)
        }
    }
}
</script>

<style scoped>
    .twine{
        filter: invert()
    }
</style>