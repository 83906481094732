<template>
    <div class="flex justify-between py-2">
        <div class="">
            <img src="@/assets/img/p.png" alt="Platinum Tags Media Logo" class="w-24 relative -left-5">
        </div>
        <div class="my-auto pr-2">
            <MenuButton @clicked="toggleMenu"/>
        </div>
    </div>
</template>

<script>
import MenuButton from '@/components/MenuButton'
export default {
    name: 'TopNavbar',
    components: {
        MenuButton
    },
    data(){
        return {
            isOpened: false
        }
    },

    methods: {
        toggleMenu(){
            this.isOpened = !this.isOpened
            this.$emit('toggle', this.isOpened)
        }
    }
}
</script>

<style>

</style>